import { Component, HostListener, OnInit, ViewChild } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpEvent,
  HttpEventType,
  HttpResponse,
  HttpProgressEvent,
  HttpRequest,
} from "@angular/common/http";
import { IMultiSelectOption } from "angular-2-dropdown-multiselect";
import { Camera, Settings, Permission, User } from "../video/video";
import * as moment from "moment";
import { VideoService } from "../video/video.service";
import { FlashMessageComponent } from "../flash-message/flash-message.component";
import { FlashMessageService } from "../flash-message/flash-message.service";

@Component({
  selector: "app-edit-users",
  templateUrl: "./edit-users.component.html",
  styleUrls: ["./edit-users.component.css"],
})
export class EditUsersComponent implements OnInit {
  loadTime = moment().unix();
  showAddUser = false;
  newUserName = "";
  deleteUserName = "";
  currentUserId;
  currentUserPermissions;
  userData;
  permittedUserData;
  changesMade;
  selectedUser;
  editingUser = {} as any;
  blankUser = {} as any;
  permissions = [
    { name: "live", show: true, descString: "Live viewing of allowed cameras" },
    {
      name: "ptz",
      show: true,
      descString: "Move ptz cameras and set patrols (Needs live permission)",
    },
    {
      name: "playback",
      show: false,
      descString: "View recordings from allowed cameras",
    },
    {
      name: "export",
      show: false,
      descString:
        "Export recordings for allowed cameras (Needs playback permission)",
    },
    {
      name: "allcameras",
      show: true,
      descString: "Allow access to all cameras",
    },
    { name: "vnc", show: false, descString: "Set central screens views" },
    { name: "log", show: true, descString: "View system logs" },
    {
      name: "sequence",
      show: false,
      descString: "Set video wall display sequences",
    },
    {
      name: "configstreams",
      show: true,
      descString: "Edit live stream configurations",
    },
    {
      name: "allow2x2",
      show: false,
      descString: "Can view 4 cameras on screen at once",
    },
    {
      name: "allow3x3",
      show: false,
      descString: "Can view 4 and 9 cameras on screen at once",
    },
    { name: "ftp", show: true, descString: "Copy files to FTP server" },
    { name: "users", show: true, descString: "Edit user permissions" },
    { name: "test", show: true, descString: "User is for test purposes only" },
  ];
  selectedCameras = [];
  dropdownSettings = {};
  camerasSelected = [];
  wallsSelected = [];
  cameraOptions: IMultiSelectOption[] = [];
  wallOptions: IMultiSelectOption[] = [];

  @ViewChild("camSelect", { static: false }) public camSelect;
  @ViewChild("wallSelect", { static: false }) public wallSelect;
  @ViewChild("fmDialog", { static: true })
  public fmDialog: FlashMessageComponent;

  @HostListener("window:keydown", ["$event"])
  handleKeyDown(event: KeyboardEvent) {
    this.doc_keyDown(event);
  }

  constructor(
    private flashService: FlashMessageService,
    private http: HttpClient,
    private videoService: VideoService
  ) {}

  ngOnInit(): void {
    var _this = this;
    this.flashService.register(this.fmDialog);
    this.dropdownSettings = {
      itemsShowLimit: 0,
      badgeShowLimit: 0,
      labelKey: "name",
      enableCheckAll: true,
      showCheckAll: true,
      showUncheckAll: true,
      dynamicTitleMaxItems: 3,
      buttonClasses: "form-control",
      containerClasses: "ng-pristine",
      displayAllSelectedText: true,
    };
    this.blankUser.permissionsObj = {} as any;
    this.blankUser.camerasObj = {} as any;
    this.blankUser.wallsObj = {} as any;
    this.permissions.forEach(function (key) {
      _this.blankUser.permissionsObj[key.name] = false;
    });
    this.blankUser.id = null;
    this.videoService.getUserId().subscribe((user) => {
      this.currentUserId = user.id;
    });
    this.videoService.getVideoList().subscribe((cams) => {
      var camOptions = [];
      cams.forEach((x) => {
        camOptions.push({
          id: x.id,
          name: x.id + " " + x.location,
        });
        this.blankUser.camerasObj[x.id] = false;
      });
      this.cameraOptions = camOptions;
      this.editingUser = JSON.parse(JSON.stringify(this.blankUser));
    });
    this.videoService.getWallList().subscribe((walls) => {
      var wOptions = [];
      walls.forEach((wall) => {
        wOptions.push({
          id: wall,
          name: wall,
        });
        this.blankUser.wallsObj[wall] = false;
      });
      this.wallOptions = wOptions;
      this.editingUser = JSON.parse(JSON.stringify(this.blankUser));
    });
    this.getUserList();
  }

  getUserList() {
    this.videoService.getPermissionsList().subscribe((p) => {
      this.currentUserPermissions = p;
      this.videoService.getUsersList().subscribe((users) => {
        if (p.superuser)
          this.permittedUserData = users.filter(
            (x) => !x.config || !x.config.superuser
          );
        else {
          this.permittedUserData = users.filter((x) => {
            var superUser = x.config && x.config.superuser;
            var testUser =
              (x.id && x.id.match(/[Tt]est/)) ||
              (x.config &&
                x.config.permissions &&
                x.config.permissions.includes("test"));
            return !superUser && !testUser;
          });
        }
        this.userData = this.permittedUserData;
      });
    });
  }
  search(q: string): void {
    if (q == "") {
      this.userData = this.permittedUserData;
    } else {
      this.userData = this.permittedUserData.filter((s) =>
        s.id && typeof s.id === "string" ? s.id.includes(q) : false
      );
    }
  }

  doubleClick(user) {
    var _this = this;
    this.changesMade = false;
    this.selectedUser = user;
    this.editingUser.id = this.selectedUser.id;
    this.selectedCameras = [];
    var hasConfig = _this.selectedUser.config;
    var hasPermissions = hasConfig && this.selectedUser.config.permissions;
    var hasCams = hasConfig && this.selectedUser.config.cameras;
    var hasWalls = hasConfig && this.selectedUser.config.walls;
    this.permissions.forEach(function (key) {
      _this.editingUser.permissionsObj[key.name] =
        hasPermissions &&
        _this.selectedUser.config.permissions.includes(key.name);
    });
    this.camerasSelected = [];
    this.cameraOptions.forEach(function (x) {
      _this.editingUser.camerasObj[x.id] =
        hasCams && _this.selectedUser.config.cameras.includes(x.id);
      if (_this.editingUser.camerasObj[x.id]) {
        _this.camSelect.setSelected(null, x);
      }
    });
    this.wallsSelected = [];
    this.wallOptions.forEach(function (x) {
      _this.editingUser.wallsObj[x.id] =
        hasWalls && _this.selectedUser.config.walls.includes(x.id);
      if (_this.editingUser.wallsObj[x.id]) {
        _this.wallSelect.setSelected(null, x);
      }
    });
  }

  changePermisson(option: string) {
    this.changesMade = true;
    this.editingUser.permissionsObj[option] = !this.editingUser.permissionsObj[
      option
    ];
    if (option.match("allcameras")) {
      this.camerasSelected = [];
      Object.keys(this.blankUser.camerasObj).forEach((x) => {
        this.editingUser.camerasObj[x] = false;
      });
    } else if (option.match("playback")) {
      if (!this.editingUser.permissionsObj[option]) {
        this.editingUser.permissionsObj["export"] = false;
      }
    } else if (option.match("live")) {
      if (!this.editingUser.permissionsObj[option]) {
        this.editingUser.permissionsObj["ptz"] = false;
      }
    } else if (option.match("walls")) {
      this.wallsSelected = [];
      Object.keys(this.blankUser.wallsObj).forEach((x) => {
        this.editingUser.wallsObj[x] = false;
      });
    }
  }

  commitChanges(): void {
    var _this = this;
    var submitChanges = { id: this.editingUser.id, config: {} as any };
    submitChanges.config.permissions = [];
    Object.keys(this.editingUser.permissionsObj).forEach((x) => {
      if (_this.editingUser.permissionsObj[x] && x != "walls") {
        submitChanges.config.permissions.push(x);
      }
    });
    submitChanges.config.cameras = [];
    this.camerasSelected.forEach((x) => {
      submitChanges.config.cameras.push(x);
    });
    submitChanges.config.walls = [];
    this.wallsSelected.forEach((x) => {
      submitChanges.config.walls.push(x);
    });
    var updateArray = [];
    updateArray.push(submitChanges);
    if (this.selectedUser)
      this.http.put<any>("/users", updateArray).subscribe(
        (res) => {
          if (res) {
            _this.selectedUser = Object.assign(
              _this.selectedUser,
              submitChanges
            );
            this.changesMade = false;
          }
        },
        (e) => {
          this.flashService
            .showThis(
              "Error Changing Permissions For '" + _this.selectedUser.id + "'",
              e.statusText +
                " : " +
                (e.error.details ? e.error.details : e.error),
              false
            )
            .then((res) => {
              this.messageModalReset();
            })
            .catch((err) => {
              this.messageModalReset();
            });
        }
      );
  }

  reload(): void {
    if (this.selectedUser) this.doubleClick(this.selectedUser);
    else {
      var id = this.editingUser.id;
      this.cancelChanges();
      this.editingUser.id = id;
    }
  }
  cancelChanges(): void {
    var _this = this;
    this.changesMade = false;
    this.selectedUser = null;
    this.editingUser = JSON.parse(JSON.stringify(this.blankUser));
    this.wallsSelected = [];
    this.camerasSelected = [];
  }

  showOption(option: string) {
    var show = true;
    var thisPermission = this.permissions.find((x) => option == x.name);
    switch (option) {
      case "ftp":
      case "test":
      case "users":
        show =
          this.currentUserPermissions && this.currentUserPermissions.superuser;
        break;
      default:
        show = thisPermission ? thisPermission.show : false;
        break;
    }
    return show;
  }
  optionDescription(option: string) {
    var descString;
    var thisPermission = this.permissions.find((x) => option == x.name);
    switch (option) {
      default:
        descString = thisPermission ? thisPermission.descString : null;
        break;
    }
    if (descString) return option + " - " + descString;
    return option;
  }
  unEdittablePermission(option: string) {
    if (option == "sequence") return true;
    if (
      option == "ptz" &&
      (!this.editingUser ||
        !this.editingUser.permissionsObj ||
        !this.editingUser.permissionsObj["live"])
    )
      return true;
    if (
      option == "export" &&
      (!this.editingUser ||
        !this.editingUser.permissionsObj ||
        !this.editingUser.permissionsObj["playback"])
    )
      return true;
    if (this.currentUserPermissions && this.currentUserPermissions.superuser)
      return false;
    if (option == "users") return true;
    if (option == "users" && this.editingUser.id == this.currentUserId)
      return true;
    return false;
  }

  messageModalReset(): void {
    this.flashService.resetModal();
  }

  connectionTimedOut() {
    if (moment().unix() - this.loadTime >= 3) return true;
    else return false;
  }

  hideAddUser() {
    this.newUserName = "";
    this.deleteUserName = "";
    this.showAddUser = false;
  }

  addNewUser(): void {
    var _this = this;
    var data = {
      user: this.newUserName,
    };
    this.http.put<any>("/user/new", data).subscribe(
      (res) => {
        if (res) {
          _this.newUserName = "";
        }
      },
      (e) => {
        if (e.statusText == "OK") {
          _this.newUserName = "";
        } else {
          this.flashService
            .showThis(
              "Error Adding User '" + _this.newUserName + "'",
              e.statusText +
                " : " +
                (e.error.details ? e.error.details : e.error),
              false
            )
            .then((res) => {
              this.messageModalReset();
            })
            .catch((err) => {
              this.messageModalReset();
            });
        }
      }
    );
  }

  deleteUser(): void {
    var _this = this;
    var data = {
      user: this.deleteUserName,
    };
    this.http.put<any>("/user/delete", data).subscribe(
      (res) => {
        if (res) {
          _this.deleteUserName = "";
        }
      },
      (e) => {
        if (e.statusText == "OK") {
          _this.deleteUserName = "";
        } else {
          this.flashService
            .showThis(
              "Error Deleting User '" + _this.deleteUserName + "'",
              e.statusText +
                " : " +
                (e.error.details ? e.error.details : e.error),
              false
            )
            .then((res) => {
              this.messageModalReset();
            })
            .catch((err) => {
              this.messageModalReset();
            });
        }
      }
    );
  }

  doc_keyDown(e: KeyboardEvent) {
    var _this = this;
    if (e.altKey && e.key == "a") this.showAddUser = !this.showAddUser;
  }
}
